// This file is automatically compiled by Webpack, along with any src files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
// finally giving turbolinks a try...
// https://github.com/turbolinks/turbolinks
require("turbolinks").start()
require('@rails/activestorage').start()
require('channels')
// when Bootstrap5 comes out jQuery will die...
require('jquery')
require('bootstrap/dist/js/bootstrap')
window.$ = $

require('datatables.net')(window, $)
require('datatables.net-select')(window, $)
// the following two lines (and yarn add datatables.net-bs4) were missing to get data tables pagination buttons working :-)
require('datatables.net-bs4')
require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")

// my own javascript code
require("custom/stuff")

// another datepicker attempt
// https://flatpickr.js.org/getting-started/
// and this one worked ! did yarn add flatpickr and added css in application.css as per https://github.com/flatpickr/flatpickr/issues/141
const flatpickr = require("flatpickr");

// trying magnific popup
import "magnific-popup";
// CSS was not working out of the box, trying to add it manually with relative path to node_modules
require("magnific-popup/dist/magnific-popup.css")


$(document).ready(function() {

    $('.image-popup-vertical-fit').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        image: {
            verticalFit: true
        }

    });

    $('.image-popup-fit-width').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        image: {
            verticalFit: false
        }
    });

    $('.image-popup-no-margins').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        closeBtnInside: false,
        fixedContentPos: true,
        mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
        image: {
            verticalFit: true
        },
        zoom: {
            enabled: true,
            duration: 300 // don't foget to change the duration also in CSS
        }
    });

});

// import { selectize } from '../components/selectize' // import the component

// for datepicker - https://github.com/platonic7/datetimepicker
// could not get it to work
// require("moment/locale/en")
// require("tempusdominus-bootstrap-4")

import '../stylesheets/application'

import '../components/selectize' // import the component

// selectize(); // init the component comment that line for now

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    //$('.bs-component [data-toggle="popover"]').popover();
    //$('.bs-component [data-toggle="tooltip"]').tooltip();
})

